import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import messages from '../../../../consts/messages'
import s from './CompanyUserSelector.module.scss'
import { UIDropDownMenu, UILayout } from 'bora-material-ui'
import { translate } from '../../../Common/Translator'
import { isEmpty, pathOr } from 'ramda'

const dropDownStyles = {
  width: '100%',
  margin: '0',
  padding: '0',
}

function CompanyItemTemplate(props) {
  if (!props) return null
  const { legalName = '' } = !props.value || isEmpty(props.value) ? props : props.value

  return (
    <div className={cn(s.listItem, { [s.selected]: props.selected })}>
      <span className={s.itemText}>{legalName}</span>
    </div>
  )
}

CompanyItemTemplate.defaultProps = {
  legalName: '',
}

CompanyItemTemplate.propTypes = {
  legalName: PropTypes.string,
  selected: PropTypes.bool,
  value: PropTypes.shape({ legalName: PropTypes.string.isRequired }).isRequired,
}

const MemberItemTemplate = (props) => {
  if (!props) return null
  const { firstName = '', lastName = '' } = isEmpty(props.value) ? props : props.value

  return (
    <div className={cn(s.listItem, { [s.selected]: props.selected })}>
      <span className={s.itemText}>
        {firstName} {lastName}
      </span>
    </div>
  )
}

MemberItemTemplate.defaultProps = {
  value: {},
}

MemberItemTemplate.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  value: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
}

function SelectedCompanyItemTemplate(props) {
  if (!props) return null
  return <CompanyItemTemplate selected {...props} />
}

function SelectedMemberItemTemplate(props) {
  if (!props) return null
  return <MemberItemTemplate selected {...props} />
}

const CompanyUserSelector = (props) => {
  const { limit, balance, limitFormatted, balanceFormatted } = props.creditInfo || {}
  const lowLimitLevel = pathOr(0.1, ['brandProps', 'companyMembers', 'lowCreditLimitLevel'])(window)
  const lowBalance = limit && balance <= limit * lowLimitLevel
  const allTranslation = translate(messages.allOption).toUpperCase()
  const allOption = {
    firstName: allTranslation,
    legalName: allTranslation,
    customerId: 'all',
    companyId: 'all',
    isAllOption: true,
  }
  const companiesOptions = props.allOptionEnabled ? [allOption, ...props.companies] : props.companies
  const membersOptions = props.allOptionEnabled ? [allOption, ...props.users] : props.users

  return (
    <div className={s.wrapper}>
      <div className={s.control} data-testid="company-user-selector-wrapper">
        <span className={s.controlLabel}>{translate(messages.chooseCompany)}</span>
        <UILayout data-testid="company-user-selector">
          <UIDropDownMenu
            itemsArr={companiesOptions}
            itemTemplate={CompanyItemTemplate}
            selectionRenderer={SelectedCompanyItemTemplate}
            select={(value) => props.onCompanySelect(value)}
            initValue={companiesOptions.findIndex((c) => c.companyId === props.companyId)}
            styles={dropDownStyles}
          />
        </UILayout>
      </div>
      {limit && (
        <div className={s.control} data-testid="company-user-credit-view">
          <span className={s.controlLabel}>{translate(messages.monthlyCredit)}</span>
          <span className={s.creditInfo}>
            <span className={cn(s.creditBalance, { [s.low]: lowBalance })}>{balanceFormatted}</span>
            <span className={s.divider}>/</span>
            <span className={s.creditLimit}>{limitFormatted}</span>
          </span>
        </div>
      )}
      {props.users && (
        <div className={s.control} data-testid="company-user-user-wrapper">
          <span className={s.controlLabel}>{translate(messages.chooseUser)}</span>
          <UILayout data-testid="company-user-user-selector">
            <UIDropDownMenu
              itemsArr={membersOptions}
              itemTemplate={MemberItemTemplate}
              selectionRenderer={SelectedMemberItemTemplate}
              select={(value) => props.onUserSelect(value)}
              initValue={membersOptions.findIndex((c) => c.customerId === props.customerId)}
              styles={dropDownStyles}
            />
          </UILayout>
        </div>
      )}
    </div>
  )
}

CompanyUserSelector.propTypes = {
  companies: PropTypes.array.isRequired,
  companyId: PropTypes.number,
  customerId: PropTypes.number,
  creditInfo: PropTypes.shape({
    balance: PropTypes.string.isRequired,
    balanceFormatted: PropTypes.number.isRequired,
    limit: PropTypes.string.isRequired,
    limitFormatted: PropTypes.number.isRequired,
  }),
  onCompanySelect: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func,
  allOptionEnabled: PropTypes.bool,
}

export default CompanyUserSelector
